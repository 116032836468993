import {AccordionContainerDPC, AccordionDPC, PageContainerDPC, PrintButtonDPC} from '@dpc-fe/shared';
import React from 'react';
import {graphql} from "gatsby";
import {prop} from "../../functions/functions";

export default ({data, pageContext}) => {

    const charArray = () => {
        var a = [], i = 'a'.charCodeAt(0), j = 'z'.charCodeAt(0);
        for (; i <= j; ++i) {
            a.push(String.fromCharCode(i));
        }
        return a;
    }

    const glossarioItem = () => {
        let glossarioItem = [];
        charArray().forEach((item, index) => {
            let obj = {};
            obj[item.toLowerCase()] = data.allNodeGlossario.edges.filter(element => element.node.field_lettera.toLowerCase() === item);
            glossarioItem.push(obj);
        });
        return glossarioItem;
    }

    return (
        <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                          menuItems={pageContext.menu}
                          title={data.node.title}
                          env={process.env.ENV}
                          lang={pageContext.lang}
                          pageId={data.node.drupal_internal__nid}
                          slugTraduzione={data.node.relationships.field_riferimento_traduzione ? data.node.relationships.field_riferimento_traduzione.fields.slug : ''}
                          minisiti={prop}
                          immagineShare={data.node.relationships?.field_immagine_dettaglio ? process.env.PORTALE_URL + data.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL: process.env.PORTALE_URL + pageContext.socialImg?.relationships?.image?.localFile?.publicURL}
                          abstractShare={data.node.body?.processed ? data.node.body.processed.replace(/<\/?[^>]+(>|$)/g, "") : ''}
                          slug={process.env.PORTALE_URL + '/' + pageContext.lang + data.node.fields.slug}
                          menuFooterItems={pageContext.menuFooter}
        >
            <section className="container page-glossary">
                <h1>{data.node.field_titolo_esteso}</h1>
                <div className="px-custom">
                    <div id="tabGlossario" className="tab-custom">
                        <div className="nav-tabs-wrapper">
                            <ul className="nav nav-tabs auto" role="tablist">
                                {charArray().map((item, index) => (
                                    <li className="nav-item">
                                        <a role="tab" data-toggle="tab" id={`tabLink${item.toUpperCase()}`}
                                           href={`#tabPanel${item.toUpperCase()}`}
                                           aria-controls={`tabPanel${item.toUpperCase()}`}
                                           aria-selected={index === 0 ? 'true' : 'false'}
                                           className={`nav-link ${index === 0 && 'active'}`}>{item.toUpperCase()}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="tab-content">
                            {glossarioItem().map((item, index) => {
                                let letter = Object.keys(item)[0];
                                return (

                                    <div role="tabpanel" id={`tabPanel${letter.toUpperCase()}`}
                                         aria-labelledby={`tabLink${letter.toUpperCase()}`}
                                         className={`tab-pane fade ${index === 0 && 'show active'}`}>
                                        {(Array.isArray(item[letter]) && item[letter].length > 0) &&
                                        <AccordionContainerDPC id={letter.toUpperCase()}>
                                            {item[letter].map((element, index) => (
                                                <AccordionDPC
                                                    accordionId={letter.toUpperCase()}
                                                    id={index}
                                                    title={element.node.field_titolo_esteso}
                                                    body={element.node.body ? element.node.body.processed : ''}
                                                />
                                            ))}
                                        </AccordionContainerDPC>
                                        }
                                    </div>)
                            })}
                        </div>
                    </div>
                    <PrintButtonDPC/>
                </div>
            </section>
        </PageContainerDPC>
    );
};

export const query = graphql`
  query($slug: String!) {
    node:nodeElencoGlossario (fields: { slug: { eq: $slug } }) {
      title
      field_titolo_esteso
      drupal_internal__nid
      fields {
        slug
      }
      body {
        value
        processed
      }
      relationships {
        field_riferimento_traduzione {
          fields {
            slug
          }
        }
      }
    },
    allNodeGlossario (filter: {field_codice_lingua: {eq: false}}) {
      edges {
        node {
          body {
            processed
          }
          title
          field_titolo_esteso
          field_lettera
        }
      }
    }
  }
`;
